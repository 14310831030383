/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { Base64 as b64 } from "js-base64";
import { format as fmt } from "date-fns";
import fr from "date-fns/locale/fr";
import numeral from "numeral";

import { addMonth, addDays } from "@/services/helper";

/**
 * Fonction retournant deux dates par rapport à une période donnée (en mois)
 * ou les dates start & end passées en arg.
 *
 * @param {Object} dates period ou start, end
 * @returns sObject of start, end
 */
export function periodToDates(dates) {
  let start, end;
  if (dates.period) {
    end = addDays(new Date(), 1);
    start = addMonth(new Date(), -dates.period);
  } else {
    end = addDays(dates.end, 1);
    start = dates.start;
  }
  return { start, end };
}

export function wait(amount = 1) {
  let timeout;
  const p = new Promise((resolve) => {
    timeout = setTimeout(resolve, amount * 1000);
  });
  p.cancel = () => clearTimeout(timeout);
  return p;
}

export function format(date, fmtStr, opts) {
  return fmt(date, fmtStr, { ...opts, locale: fr });
}

export function numberFormat(value, _format) {
  return numeral(value).format(_format);
}

// eslint-disable-next-line
export let store = null;
export function initStore(_store) {
  store = _store;
}

export const now = Date.now();

export function getDomainImg(id) {
  return require(`~/assets/images/domains/${store.state.domains[id || undefined].slug}.svg`);
}

export function getIconeImg(path) {
  if (!path) return require(`~/assets/images/activities/patgames/multi_activity.png`);

  const filename = path.includes(".png") ? path.split(".")[0] : path;
  if (path.includes("patgames/")) {
    try {
      return require(`~/assets/images/activities/${filename}.png`);
    } catch (error) {
      return require(`~/assets/images/activities/patgames/multi_activity.png`);
    }
  }
  return require(`~/assets/images/activities/${filename}.png`);
}

function getCookieByName(name) {
  const match = document.cookie.match(new RegExp(`(?:^| )${name}=([^;]+)`));
  if (match) return match[1];
  return null;
}

export function extractClaimsFromJWT() {
  const token = localStorage.authtoken || getCookieByName("authtoken");
  return token && JSON.parse(b64.decode(token.split(".")[1]));
}

export function stringToDate(dateString) {
  const [dd, mm, yyyy] = dateString.split("-");
  return new Date(`${yyyy}-${mm}-${dd}`);
}

export function formatTime(time, formatH) {
  let seconds = Math.trunc(time * 60);
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;
  const hours = Math.floor(minutes / 60);

  // if (formatH) {
  //  return hours + '.' + ("0" + minutes).slice(-2);
  // }
  if (formatH === "minutes") {
    return `${minutes < 10 ? `0${minutes}` : minutes}:${`0${seconds}`.slice(-2)}`;
  }
  minutes %= 60;
  return `${hours < 10 ? `0${hours}` : hours}:${`0${minutes}`.slice(-2)}`;
  // return ("0" + hours)/*.slice(-2)*/ + ':' + ("0" + minutes).slice(-2)/* + ':' + ("0" + seconds).slice(-2)*/;
}

/**
 * Ajoute un '0' au début de `value` s'il ne fait qu'un chiffre.
 *
 * @param {Number} value
 * @returns String | Number
 */
export function trim(value) {
  return `${value}`.length === 1 ? `0${value}` : value;
}

/**
 * Fonction formattant des millisecondes en heurs:minutes
 *
 * @param {Number} milli Milliseconds
 * @returns HH:mm
 */
export function milliToDuration(milli) {
  const hours = Math.floor(milli / 3600 / 1000);
  const min = Math.floor(milli / 60 / 1000) - hours * 60;
  let value = "";
  if (hours > 0) {
    value += `${trim(hours)}h`;
  }
  if (min > 0) {
    value += ` ${trim(min)}min`;
  }
  return value;
}

export function buildString(str, array) {
  let i = 0;
  // eslint-disable-next-line no-plusplus
  return str.replace(/%s/g, () => array[i++]);
}

export function detectBrowser() {
  return ((agent) => {
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("edg") > -1:
        return "chromium based edge (dev or canary)";
      case agent.indexOf("opr") > -1 && !!window.opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!window.chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  })(window.navigator.userAgent.toLowerCase());
}

export function getEvolutionPercent(newValue, oldValue) {
  // eslint-disable-next-line no-nested-ternary
  return oldValue !== 0
    ? Math.round(((newValue - oldValue) / oldValue) * 100)
    : newValue !== oldValue
    ? 100
    : "0";
}

export function getEvolutionTendance(newValue, oldValue) {
  // eslint-disable-next-line no-nested-ternary
  return oldValue !== 0 ? (newValue - oldValue) / oldValue : newValue !== oldValue ? 1 : 0;
}

export function checkPermission(permKey) {
  if (!permKey) return false;
  const permissions = JSON.parse(localStorage.getItem("perm"));
  return permissions[permKey] ? !permissions[permKey.key][permKey.type] : false;
}

export function formatDate(date, options, lang = navigator.language) {
  return new Intl.DateTimeFormat(lang, options).format(date);
}

export function hexToRgb(hex) {
  if (hex === "transparent") return { r: 255, g: 255, b: 255 };

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexa = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexa);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Computed and return the hexadecimal color of a text based on his background for mor lisibility
 * @param {string} color The backgroundColor of the text
 * @returns the Color of the text based on the background color (black or white)
 */
export function textColor(color) {
  const c = hexToRgb(color);
  return c.r * 0.299 + c.g * 0.587 + c.b * 0.114 > 186 ? "#000" : "#fff";
}

export function getPropertyValue(obj1, dataToRetrieve) {
  return dataToRetrieve
    .split(".") // split string based on `.`
    .reduce((o, k) => {
      return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, obj1); // set initial value as object
}

export function excludeElement(event, filters, idKey = "id") {
  return Object.entries(filters).some(([key, value]) => {
    const searchEmptyValue = key.startsWith("!");
    const realKey = searchEmptyValue ? key.slice(1) : key;
    const eventValue = getPropertyValue(event, realKey);
    if (key === 'eventType') {
      return !checkForEventWithCR(event, value);
    }
    if (searchEmptyValue) {
      return eventValue.length > 0;
    }
    if (key === 'colorDisplay') {
      return false
    }
    // if event value is an array we must check if the array contains one of the filters values
    if (Array.isArray(eventValue)) {
      if (Array.isArray(value)) {
        const filterValue = value.find(
          (a) => eventValue.findIndex((b) => b[idKey] === a[idKey]) >= 0,
        );
        return !(filterValue && checkForSameActivityType(realKey, event, filterValue));
      }
      if (typeof value === "object" && eventValue.length > 0 && typeof eventValue[0] === "object") {
        return !eventValue.find((a) => a?.[idKey] === value?.[idKey]);
      }
      return !eventValue.find((a) => a === value);
    }
    if (Array.isArray(value)) {
      if (typeof eventValue === "object") {
        const filterValue = value.find((a) => a[idKey] === eventValue[idKey]);
        return !(filterValue && checkForSameActivityType(realKey, event, filterValue));
      }
      const filterValue = value.find((a) => a[idKey] === eventValue);
      return !(filterValue && checkForSameActivityType(realKey, event, filterValue));
    }
    // if one of the filters is verified event is not excluded
    return eventValue !== value;
  });
}

/**
 * Fonction retournant un booléen si l'event à ou non un CR par rapport au type à afficher.
 *
 * @param {Object} event Event à checker
 * @param {Number} value Type à afficher (0: Tous, 1: avec CR, 2: sans CR)
 * @returns Boolean
 */
function checkForEventWithCR(event, value) {
  switch(value) {
    case 1:
      return event.repId !== undefined;
    case 2:
      return event.repId === undefined;
  }
  return true; // case 0
}

/**
 * Fonction retournant true si le type de l'évenement correspond au filtre spécifique sinon false.
 * Pour des questions de vérification dans la méthode excludeElement, si le type de clé ne correspond pas
 * à 'activities', cette fonction retourne true.
 *
 * @param {String} key Type de recherche
 * @param {Object} event L'évenement à vérifier
 * @param {Object} filter Le filtre qui est appliqué pour l'événement
 * @returns Bool
 */
function checkForSameActivityType(key, event, filter) {
  if (key === "activities") {
    try {
      return event.type_act === filter.type;
    } catch (e) {
      // no op
    }
  }
  return true;
}

// took from here https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id/6860916#6860916
/**
 * Generates a unique UUID v4 identifier
 * @returns {string} a valid UUID v4
 */
export function generateGUID() {
  // eslint-disable-next-line no-bitwise
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

/**
 * Generated a download link and automatically laucnt the download
 * @param {Blob} blob a blob
 * @param {string} filename the name of the file to generate. Ex: users.csv
 * @returns A link to download again
 */
export function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);
  // Create a new anchor element
  const a = document.createElement("a");
  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || "download";

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener?.("click", clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener("click", clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

export function mapDuration(activity) {
  if (activity.cae_data?.perimeter?.duration) {
    const { duration } = activity.cae_data.perimeter;
    let hours = `${Math.floor(duration / 60)}`;
    let minutes = `${duration % 60}`;
    hours = hours.length === 2 ? hours : `0${hours}`;
    minutes = minutes.length === 2 ? minutes : `0${minutes}`;

    return `${hours}:${minutes}`;
  }
  return "01:00";
}

/*
Verification du mot de passe selon la policy appliquée
*/

export function checkPassword(password, rules) {
  let pwdLengthOk;
  let pwdUpperOk;
  let pwdLowerOk;
  let pwdNumberOk;
  let pwdSpecialOk;

  // taille du mot de passe
  if (rules.size.max) {
    if (password.length >= rules.size.min && password.length <= rules.size.max) pwdLengthOk = true;
    else pwdLengthOk = false;
  } else if (password.length < rules.size.min) pwdLengthOk = false;
  else pwdLengthOk = true;
  // nombre de majuscule
  if (rules.char.upper.authorized) {
    const nbUpper = password.length - password.replace(/[A-Z]/g, "").length;
    if (rules.char.upper.max) {
      if (nbUpper >= rules.char.upper.min && nbUpper <= rules.char.upper.max) pwdUpperOk = true;
      else pwdUpperOk = false;
    } else if (nbUpper < rules.char.upper.min) pwdUpperOk = false;
    else pwdUpperOk = true;
  } else pwdUpperOk = true;

  // nombre de minuscule
  if (rules.char.lower.authorized) {
    const nbLower = password.length - password.replace(/[a-z]/g, "").length;
    if (rules.char.lower.max) {
      if (nbLower >= rules.char.lower.min && nbLower <= rules.char.lower.max) pwdLowerOk = true;
      else pwdLowerOk = false;
    } else if (nbLower < rules.char.lower.min) pwdLowerOk = false;
    else pwdLowerOk = true;
  } else pwdLowerOk = true;

  // nombre de chiffre
  if (rules.number.authorized) {
    const nbNumber = password.length - password.replace(/[0-9]/g, "").length;
    if (rules.number.max) {
      if (nbNumber >= rules.number.min && nbNumber <= rules.number.max) pwdNumberOk = true;
      else pwdNumberOk = false;
    } else if (nbNumber < rules.number.min) pwdNumberOk = false;
    else pwdNumberOk = true;
  } else pwdNumberOk = true;

  // check caractère spéciaux
  if (rules.special.authorized) {
    const authSpecial = rules.special.include;
    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
    const splittedSpecialChars = specialChars.split("");
    const u = splittedSpecialChars.filter((x) => authSpecial.indexOf(x) === -1);
    const pp = u.filter((x) => password.split("").includes(x));
    if (pp.length > 0) pwdSpecialOk = false;
    else {
      const res = authSpecial.map((e) => password.split("").filter((k) => k === e).length);
      const numberSpecial = res.reduce((a, b) => a + b, 0);
      if (rules.special.max) {
        if (numberSpecial >= rules.special.min && numberSpecial <= rules.special.max)
          pwdSpecialOk = true;
        else pwdSpecialOk = false;
      } else if (numberSpecial < rules.special.min) pwdSpecialOk = false;
      else pwdSpecialOk = true;
    }
  } else pwdSpecialOk = true;

  return {
    pwdLength: pwdLengthOk,
    pwdUpper: pwdUpperOk,
    pwdLower: pwdLowerOk,
    pwdNumber: pwdNumberOk,
    pwdSpecial: pwdSpecialOk,
  };
}

export const SWATCHES_COLORS = [
  "#d0cee9",
  "#e7b0d2",
  "#b694C9",
  "#6E8ECA",
  "#5C4BA7",
  "#C5EFFC",
  "#9BCBDF",
  "#78C2E8",
  "#1C57AE",
  "#FDF8D4",
  "#C3E3AD",
  "#7AD0A7",
  "#72C55A",
  "#C8ECE3",
  "#90DDDC",
  "#67CEB9",
  "#4A9387",
  "#F0DD7C",
  "#F9C276",
  "#F6915A",
  "#EF7735",
  "#FF6F5D",
  "#F24E3B",
  "#AE2619",
  "#FFC9CB",
  "#E8A9AF",
  "#F27D9B",
  "#C25063",
  "#F6498F",
  "#E52D3A",
];
